<template>
  <div>
    <md-table class="px-0">
      <md-table-row class="container-instructors__head table-striped">
        <md-table-head>Creado</md-table-head>
        <md-table-head>Socio</md-table-head>
        <md-table-head># Pago</md-table-head>
        <md-table-head>Estado del pago</md-table-head>
        <md-table-head>Dia de pago</md-table-head>
        <md-table-head>Metodo</md-table-head>
        <md-table-head>Monto</md-table-head>
      </md-table-row>
      <!--     Data table -->
      <md-table-row
        class="container-instructors__cell"
        v-for="payment in getPaymentComplete"
        :key="payment.id"
      >
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ getDateFormat(payment.created_at) }}
            </p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ payment.name }}
            </p>
          </div>
        </md-table-cell>

        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ payment.payment_details }}
            </p>
          </div>
        </md-table-cell>

        <md-table-cell>
          <div>
            <span class="bagde-complete" v-if="payment.status == 'complete'">
              Completado
            </span>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ getDateFormat(payment.payment_date) }}
            </p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ payment.payment_method }}
            </p>
          </div>
        </md-table-cell>

        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">${{ payment.amount }}</p>
          </div>
        </md-table-cell>
      </md-table-row>
      <!--   END  Data table -->
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment-timezone';

export default {
  name: 'TablePaymentsComplete',

  methods: {
    getDateFormat(date) {
      // return moment.tz(date, "America/New_York").fromNow();
      return moment(date).format('ll');
    },
  },
  computed: {
    ...mapGetters(['getPaymentComplete']),
  },
};
</script>

<style>
.md-content.md-table-content.md-scrollbar.md-theme-default {
  padding-left: 0px;
}
</style>
