<template>
  <div class="auth-layout-container bg-default">
    <div class="main-content login-app">
      <div class="auth-super-container container">
        <!-- Table -->
        <div class="row justify-content-center">
          <div>
            <div class="auth-container">
              <div class="auth-container__logo">
                <img
                  :src="
                    require(`@/assets/images/logo/logo-maquillate-white.png`)
                  "
                  alt="Maquillate logo"
                  class=""
                />
              </div>
              <div class="auth-container__form bg-white mt-4 mb-0 rounded">
                <div class="card-body w-100">
                  <div class="text-center">
                    <h2>Se ha procesado un cambio de contraseña</h2>
                    <h3>Se ha enviado un correo a su bandeja de entrada</h3>
                    <p class="text-black">
                      No olvide revisar su bandeja de correo no deseados. <br/> Si no ha llegado, puede
                      <a class="resend-text" href @click="resend">Reenviar</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RECOVERY_REQUEST } from '@/store/actions/recovery';
import { mapGetters } from 'vuex';

export default {
  name: 'SuccessRecoveryEmail',
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['recoveryEmail', 'recoveryStatus']),
  },
  methods: {
    notify(message, type) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    resend(e) {
      e.preventDefault();
      let data = {
        email: this.recoveryEmail,
      };
      this.$store
        .dispatch(RECOVERY_REQUEST, data)
        .then(() => {
          this.notify(
            'Se ha reenviado el correo de confirmación con éxito',
            'success',
          );
        })
        .catch((error) => {
          let errorCode = error.response.data.error;
          let message = "";
            if (errorCode === "passwords.throttled") {
              message = "Has excedido el número máximo de intentos. Por favor espera unos segundos antes de volver a intentarlo.";
            } else {
              message = "No se ha podido reenviar el correo de confirmación con éxito";
            }

          this.notify( message, 'danger');
        });
    },
  },
};
</script>

<style>
.inner-container-layout {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  margin: auto;
  align-items: center;
  padding: 2em;
}

.text-black {
  color: #000;
}

.resend-text{
  color: #b7227e !important;
}

</style>
