var render = function render(){var _vm=this,_c=_vm._self._c;return _c('skeleton-loading',[_c('div',{staticClass:"container-Announcements__containers-style"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-2"},[_c('skeleton-square',{attrs:{"box-properties":{
            height: '100px',
            width: '100%',
          },"count":1}})],1),_c('div',{staticClass:"col-sm-10"},[_c('skeleton-square',{attrs:{"box-properties":{
            height: '16px',
            width: '160px',
            bottom: '10px',
          },"count":4}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }