<template>
  <div>
    <div class="container-fluid container-category">
      <div class="row row-announcements">
        <div class="col-sm-8 m-0 pl-0">
          <form class="filter-flex">
            <i class="fas fa-search fa-flip-horizontal"></i>
            <input
              v-model="searchValue"
              aria-label="Search"
              class="form-control filter-input__category inputFilter me-2"
              placeholder="Buscar curso por nombre"
              type="text"
              @keydown="searchAdvertisement"
            />
            <div class="md-layout md-gutter">
              <div class="md-layout-item" style="padding: 0 !important">
                <md-field class="sort-box" style="">
                  <md-select
                    v-model="sortType"
                    class="custom-select filter inputFilter sort-select sort-portrait md-filter-input md-sort-select"
                  >
                    <md-option hidden selected value="">Filtrar por</md-option>
                    <md-option value="DESC">Nuevo</md-option>
                    <md-option value="ASC">Antiguo</md-option>
                    <md-option value="ASC_title">A-Z</md-option>
                    <md-option value="DESC_title">Z-A</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
          </form>
        </div>

        <div class="col-sm-4 text-right pr-0">
          <button class="btn btn-course" @click="openAdvertisementDialog()">
            Agregar anuncio
          </button>
        </div>
        <div class="col-12 container-Announcements mt-2 px-0">
          <div class="container-Announcements__containers">
            <template v-if="isLoading">
              <skeleton-advertisement v-for="index in 2" :key="index" />
            </template>
            <template v-else>
              <template v-if="getAdvertisements && getAdvertisements.items">
                <div
                  v-for="advertisement in getAdvertisements.items"
                  :key="advertisement.id"
                  class="container-Announcements__containers-style"
                >
                  <div class="row">
                    <div
                      class="col-12 col-sm-5 col-md-4 col-lg-2 col-xl-2 d-flex align-items-center justify-content-center"
                    >
                      <img
                        class="advertisement-poster"
                        :src="advertisement.poster.url"
                        alt="image announcement"
                      />
                    </div>
                    <div class="col-12 col-sm-7 col-md-5 col-lg-8 col-xl-8">
                      <p :class="'state-' + advertisement.status.toLowerCase()">
                        {{
                          advertisement.status === 'PUBLISH'
                            ? 'Publicado'
                            : 'Editando'
                        }}
                      </p>
                      <p class="title-announcement mb-1">
                        {{ advertisement.title }}
                      </p>
                      <p
                        class="description-announcement m-0 collapse-text"
                        v-html="advertisement.content"
                      ></p>
                    </div>
                    <div
                      class="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 container-Announcements__containers-style__options"
                    >
                      <span class="options-announcements">
                        <md-menu
                          :md-offset-x="-127"
                          :md-offset-y="-20"
                          md-size="auto"
                        >
                          <md-button md-menu-trigger>•••</md-button>

                          <md-menu-content>
                            <md-menu-item>
                              <span
                                class="pr-5 cursor"
                                href
                                @click="openAdvertisementDialog(advertisement)"
                              >
                                Editar
                              </span>
                            </md-menu-item>
                            <md-menu-item>
                              <span
                                class="pr-5 cursor"
                                href
                                @click="
                                  openDeleteAdvertisementDialog(advertisement)
                                "
                              >
                                Eliminar
                              </span>
                            </md-menu-item>
                          </md-menu-content>
                        </md-menu>
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-sm-12 text-center">
                  No hay anuncios disponibles
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="totalPages > 1"
        class="card-footer pt-4 pb-2 d-flex justify-content-end border-top-0"
      >
        <base-pagination
          :value="page"
          class="pagination-style"
          :page-count="totalPages"
          @input="paginationChange"
        ></base-pagination>
      </div>
      <advertisement-dialog
        :is-open="isOpenAdvertisementDialog"
        :selected-advertisement="selectedAdvertisement"
        @success="refetchAdvertisement"
        @close-modal="closeAdvertisementDialog"
      />
      <delete-dialog
        :is-open="isOpenDeleteAdvertisementDialog"
        @deleted="deleteAdvertisement"
        @close-modal="closeDeleteAdvertisementDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AdvertisementDialog from './components/AdvertisementDialog';
import {
  DELETE_ADVERTISEMENT,
  FETCH_ADVERTISEMENTS,
} from '../../../../store/actions/advertisement';
import DeleteDialog from '../components/DeleteDialog';
import SkeletonAdvertisement from '../../../Components/skeletons/SkeletonAdvertisement';
import BasePagination from '../../../../components/BasePagination';

export default {
  name: 'AdvertisementView',
  components: {
    AdvertisementDialog,
    DeleteDialog,
    SkeletonAdvertisement,
    BasePagination,
  },
  props: {
    perPage: {
      type: Number,
      default: 20,
    },
  },
  data: function () {
    return {
      isOpenAdvertisementDialog: false,
      selectedAdvertisement: undefined,
      searchValue: '',
      sortType: '',
      isLoading: true,
      isOpenDeleteAdvertisementDialog: false,
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['getAdvertisements']),
    totalPages() {
      return Math.ceil(this.getAdvertisements.total / this.perPage);
    },
  },
  watch: {
    sortType: function (newValue) {
      this.isLoading = true;
      this.fetchAdvertisements({
        search: this.searchValue,
        per_page: 20,
        order_by: newValue,
      });
    },
  },
  mounted() {
    this.fetchAdvertisements();
  },
  methods: {
    openAdvertisementDialog(advertisement = undefined) {
      this.isOpenAdvertisementDialog = true;
      this.selectedAdvertisement = advertisement;
    },
    closeAdvertisementDialog() {
      this.isOpenAdvertisementDialog = false;
      this.selectedAdvertisement = undefined;
    },
    openDeleteAdvertisementDialog(advertisement) {
      this.isOpenDeleteAdvertisementDialog = true;
      this.selectedAdvertisement = advertisement;
    },
    closeDeleteAdvertisementDialog() {
      this.isOpenDeleteAdvertisementDialog = false;
      this.selectedAdvertisement = undefined;
    },
    deleteAdvertisement() {
      this.$store
        .dispatch(DELETE_ADVERTISEMENT, this.selectedAdvertisement.id)
        .then(() => {
          this.closeDeleteAdvertisementDialog();
          this.refetchAdvertisement();
          this.notify('success', 'El anuncio se ha eliminado correctamente');
        })
        .catch(() =>
          this.notify('danger', 'Tenemos problemas para eliminar el anuncio'),
        );
    },
    refetchAdvertisement() {
      this.isLoading = true;
      this.fetchAdvertisements();
    },
    fetchAdvertisements(
      filter = {
        search: this.searchValue,
        per_page: 20,
        order_by: this.sortType,
      },
    ) {
      this.$store.dispatch(FETCH_ADVERTISEMENTS, filter).then(() => {
        this.isLoading = false;
      });
    },
    searchAdvertisement(event) {
      if (event.key === 'Enter') {
        this.refetchAdvertisement();
      }
    },
    notify(value, message) {
      this.$notify({
        type: value,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    async paginationChange(page) {
      this.isLoading = true;
      this.page = page;
      await this.$store.dispatch(FETCH_ADVERTISEMENTS, {
        page: this.page,
        per_page: this.perPage,
      });
      this.isLoading = false;
    },
  },
};
</script>

<style>
.filter-input__category {
  max-width: 100%;
}

.form-control {
  height: 50px;
  margin-bottom: 10px;
}

.md-sort-select {
  margin-right: 0;
}

.collapse-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 85px;
  max-height: 85px;
}

.advertisement-poster {
  width: 170px !important;
  height: 115px !important;
  max-width: 170px;
  max-height: 115px;
  object-fit: cover;
}

.row-announcements .btn-course {
  background: #b7227e;
  color: #fff;
  font-weight: 500;
  margin-left: 15px;
  margin-bottom: 8px;
  padding: 11px 14px;
  font-size: 13px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  height: 50px;
}
</style>
