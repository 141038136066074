<template>
  <div>
    <!-- Page content -->
    <div class="container mt-2">
      <!-- Table -->
      <div class="row justify-content-center auth-super-container">
        <div class="auth-container padding-password">
          <div class="auth-container__logo">
            <img
              class=""
              :src="require(`@/assets/images/logo/logo-maquillate-white.png`)"
              alt="Maquillate logo"
            />
          </div>

          <div class="auth-container__form mt-4">
            <div>
              <div class="bg-white rounded mb-0">
                <div class="card-body">
                  <div class="text-center text-muted mb-4">
                    <h2 class="text-default">SOLICITAR CAMBIO DE CORREO</h2>
                    <h3 class="text-default">Ingrese nuevo correo</h3>
                  </div>
                  <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                  >
                    <form
                      role="form"
                      @submit.prevent="handleSubmit(resetEmail)"
                    >
                      <base-input
                        alternative
                        class="mb-0"
                        name="Email"
                        :rules="{ required: true, email: true }"
                        placeholder="Email"
                        v-model="model.email"
                      ></base-input>

                      <div class="text-center">
                        <base-button
                          block
                          type="dark"
                          native-type="submit"
                          class="mt-4"
                          >Continuar</base-button
                        >
                      </div>

                      <base-alert
                        class="mt-3"
                        v-if="recoveryStatus == 'error'"
                        type="danger"
                      >
                        <strong>Error</strong> Parece que el correo no esta
                        registrado. intentalo de nuevo.
                      </base-alert>
                    </form>
                  </validation-observer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  RECOVERY_CHANGE_REQUEST,
  RECOVERY_EMAIL_REQUEST,
} from '@/store/actions/recovery';
import { USER_REQUEST } from '@/store/actions/user';
import { mapGetters } from 'vuex';

export default {
  name: 'recoveryPasswordForm',
  data() {
    return {
      model: {
        email: '',
      },
    };
  },
  methods: {
    resetEmail() {
      let data = {
        email: this.model.email,
      };
      this.$store
        .dispatch(RECOVERY_EMAIL_REQUEST, data)
        .then((response) => {
          this.$router.push('/confirmar-correo');
        })
        .catch((error) => {
          this.model.errorMessage = error.response.data.message;
        });
    },
  },
  computed: {
    ...mapGetters(['recoveryStatus', 'getProfile']),
  },
};
</script>

<style></style>
