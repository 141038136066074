<template>
  <skeleton-loading>
    <div class="container-Announcements__containers-style">
      <div class="row">
        <div class="col-sm-2">
          <skeleton-square
            :box-properties="{
              height: '100px',
              width: '100%',
            }"
            :count="1"
          />
        </div>
        <div class="col-sm-10">
          <skeleton-square
            :box-properties="{
              height: '16px',
              width: '160px',
              bottom: '10px',
            }"
            :count="4"
          />
        </div>
      </div>
    </div>
  </skeleton-loading>
</template>

<script>
import SkeletonLoading from '../../../components/Skeleton/SkeletonLoading';
import SkeletonSquare from '../../../components/Skeleton/components/SkeletonSquare';

export default {
  name: 'SkeletonAdvertisement',
  components: {
    SkeletonLoading,
    SkeletonSquare,
  },
};
</script>

<style scoped></style>
