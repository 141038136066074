<template>
  <div>
    <md-table class="px-0">
      <md-table-row class="container-instructors__head table-striped px-0">
        <md-table-head>Creado</md-table-head>
        <md-table-head>Curso</md-table-head>
        <md-table-head>Fuente</md-table-head>
        <md-table-head>Estado de retiro</md-table-head>

        <!-- <md-table-head>Dia de pago</md-table-head> -->
        <md-table-head>Monto</md-table-head>
      </md-table-row>

      <md-table-row
        class="container-instructors__cell"
        v-for="order in getOrders"
        :key="order.id"
      >
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ getDateFormat(order.created_at) }}
            </p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ order.course }}
            </p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ order.name }}
            </p>
          </div>
        </md-table-cell>

        <md-table-cell>
          <div>
            <span
              class="bagde-complete"
              v-if="order.statusPartner == 'complete'"
            >
              Completado
            </span>
            <span class="bagde-pending" v-if="order.statusPartner == 'pending'">
              Pendiente
            </span>
          </div>
        </md-table-cell>
        <!-- <md-table-cell>
          <div
            class="
              d-flex
              align-items-center
              flex-column
              align-items-center
              justify-content-center
            "
          >
            <p class="p-0 m-0 font-weight-bolder">
             
            </p>
          </div>
        </md-table-cell> -->

        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">${{ order.profit }}</p>
          </div>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_ORDERS } from '@/store/actions/payment';
import moment from 'moment-timezone';

export default {
  name: 'TableOrders',
  methods: {
    async load() {
      await this.$store.dispatch(GET_ORDERS);
    },
    getDateFormat(date) {
      // return moment.tz(date, "America/New_York").fromNow();
      return moment(date).format('ll');
    },
  },
  beforeMount() {
    this.load();
  },
  computed: {
    ...mapGetters(['getOrders']),
  },
};
</script>

<style>
.md-content.md-table-content.md-scrollbar.md-theme-default {
  padding-left: 0px;
}
</style>
