<template>
  <div class="container-modal-delete">
    <modal
      :show.sync="open"
      gradient="danger"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <h6 slot="header" class="modal-title" id="modal-title-notification">
        Se requiere tu atención
      </h6>

      <div class="py-3 text-center">
        <i class="ni ni-bell-55 ni-3x"></i>
        <h4 class="heading mt-4">Deberias leer esto!</h4>
        <p>¿Estas seguro que deseas eliminar?</p>
      </div>

      <template slot="footer">
        <div class="d-flex flex-column justify-content-center">
          <base-button @click="deleted" type="white">Eliminar</base-button>
          <base-button type="link" class="text-white" @click="closeModal"
            >Cerrar</base-button
          >
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    confirmDelete: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    deleted() {
      this.$emit('deleted');
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.confirmDelete;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
};
</script>
