<template>
  <div class="auth-layout-container bg-default">
    <div class="auth-container p-0 m-0">
      <div class="auth-super-container auth-super-container__confirmadet">
        <div class="auth-super-container container">
          <div class="row justify-content-center">
            <div class="xs-size">
              <div class="auth-container__logo">
                <img
                  :src="
                    require(`@/assets/images/logo/logo-maquillate-white.png`)
                  "
                  alt="Maquillate logo"
                  class="img__confirmadet"
                />
              </div>
              <div class="auth-container">
                <div class="bg-white mt-4 rounded mb-0 auth-container__form">
                  <div class="card-body">
                    <div class="text-center text-muted mb-0">
                      <div class="auth-container__form__title text-default">
                        <h2 class="text-default">
                          INGRESA TU NUEVO CORREO ELECTRÓNICO
                        </h2>
                      </div>
                      <div
                        class="auth-container__form__description text-default"
                      >
                        <h3 class="text-default">
                          Le enviaremos un mensaje de registro a:
                        </h3>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <base-input
                            v-model="formData.email"
                            :disabled="!isChangeEmail"
                            :placeholder="formData.email"
                          ></base-input>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 mx-auto">
                          <base href />
                          <base-button
                            v-if="!isChangeEmail"
                            type="primary btn-maquillate py-2 px-3"
                            @click="changeEmail"
                          >
                            CAMBIAR CORREO ELECTRÓNICO
                          </base-button>

                          <div v-else>
                            <base-button
                              :loading="isLoadingEmail"
                              type="primary btn-maquillate py-2 px-3"
                              @click="resetEmail()"
                            >
                              Aplicar cambio
                            </base-button>
                            <base-button
                              class="cancelar btn-maquillate-cancel py-2 px-3"
                              type="default"
                              @click="changeEmail"
                            >
                              Cancelar
                            </base-button>
                          </div>
                        </div>
                      </div>
                      <hr
                        class="my-4"
                        style="width: 100%; border-top: 3px solid #f0f0f0"
                      />

                      <div class="auth-container__form__links">
                        <button
                          class="btn btn-course float-right mt-2"
                          @click="logout"
                        >
                          CERRAR SESIÓN
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RESEND_VERIFICATION } from '@/store/actions/register';
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/login';
import BaseButton from '../../../components/BaseButton.vue';
import { UPDATE_USER_EMAIL } from '../../../store/actions/user';
import validator from 'validator';

export default {
  name: 'ConfirmUserResetEmail',
  components: {
    BaseButton,
  },
  data() {
    return {
      model: {
        email: '',
      },
      loader: true,
      isChangeEmail: false,
      isLoadingEmail: false,
      formData: {
        email: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  mounted() {
    this.formData = {
      ...this.getProfile,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT, this.getProfile.id).then(() => {
        window.location.href = '/';
      });
    },
    ErrorValidationChangeEmail(data) {
      const errors = [];

      if (!validator.default.isEmail(data.email)) {
        errors.push(
          'El formato del correo esta incorrecto o el correo está vacío',
        );
      }
      return errors;
    },
    resend() {
      this.$store
        .dispatch(RESEND_VERIFICATION, this.getProfile.email)
        .then((response) => {
          console.log(response.data);
          this.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Se ha reenviado el correo de confirmación con éxito',
          });
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Debes esperar un poco para reenviar el correo.',
          });
        });
    },
    changeEmail() {
      this.isChangeEmail = !this.isChangeEmail;
    },
    resetEmail() {
      const data = {
        email: this.formData.email ? this.formData.email : '',
      };
      let errors = this.ErrorValidationChangeEmail(data);

      if (errors.length) {
        this.notify('danger', errors.join('</br>'));
        return;
      }
      this.isLoadingEmail = true;
      this.$store
        .dispatch(UPDATE_USER_EMAIL, {
          email: this.formData.email,
        })
        .then(() => {
          this.isLoadingEmail = false;
          this.notify(
            'success',
            'Por favor revise su bandeja de entrada para confirmar su cambio de dirección de correo electrónico.',
          );
          this.isChangeEmail = !this.isChangeEmail;
        })
        .catch(() => {
          this.isLoadingEmail = false;
          this.notify(
            'danger',
            'Se ha producido un error tratando de actualizar los datos. Inténtalo más tarde.',
          );
        });
    },
    notify(value, message) {
      this.$notify({
        type: value,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>
