<template>
  <md-dialog :md-active="open" class="modalEdit__instructors modal-categories">
    <div class="w-100 position-fixed mobile-cancel">
      <span
class="material-icons close text-right"
@click="closeModal"
        >close</span
      >
    </div>
    <div class="body-modal-instructor modal-body">
      <div class="md-dialog-title text-left">
        <h3 class="text-bold">CREAR UN ANUNCIO</h3>
      </div>
      <div class="form-instructor dialog-body">
        <div class="row mb-3">
          <div class="col-md-12">
            <base-input
              v-model="form.title"
              name="name"
              placeholder="Titulo del anuncio"
            ></base-input>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <quill-editor
              ref="myQuillEditor"
              v-model="form.content"
              :options="editorOption"
            />
          </div>
        </div>
        <div class="col-12">
          <md-field class="d-none">
            <label>Cambia la imagen del anuncio</label>
            <input
              id="file-input"
              accept="image/*"
              type="file"
              @change="getFile"
            />
          </md-field>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <h4 class="mt-4 text-bold text-left">
              SELECCIONE LA IMAGEN DEL ANUNCIO
            </h4>
          </div>
          <div class="col-12 mt-3">
            <div class="imageContainer">
              <img
                v-if="this.form.poster.url"
                :src="this.form.poster.url"
                alt="productImage"
                class="imageToUpload rounded"
              />
              <img
                v-else
                alt="productImage"
                class="imageToUpload rounded"
                src="@/assets/images/logo/placeholder.png"
              />
              <div class="textUploadImage rounded" @click="openInputFile">
                <p>Sube la imagen del anuncio</p>
              </div>
            </div>
          </div>
          <p class="col-12 pt-2 text-muted">
            Para el mejor resultado, usar imagen rectangular 6720 x 2801 px.
          </p>
        </div>
        <div class="form-group row">
          <div class="col-md-12 text-left">
            <h4 class="mt-4 text-bold text-left">STATUS</h4>
          </div>
          <div class="col-md-12">
            <multiselect
              v-model="form.status"
              :close-on-select="true"
              :options="statuses"
              :searchable="true"
              :show-labels="false"
              class="select-danger details-country v-select"
              placeholder="Seleccione una seccion"
              track-by="id"
              label="label"
            >
            <template #noResult>
              <div>Sin resultados</div>
            </template>
            </multiselect>
          </div>
        </div>
        <div class="row mt-3">
          <div v-if="!isAddButton" class="col-12" style="padding-top: 15px">
            <base-button
              class="btn add-button"
              style="color: white !important; height: 50px !important"
              @click="openAddButton()"
              >AGREGAR BOTÓN
            </base-button>
          </div>
          <div v-else class="col-12">
            <div class="row" style="justify-content: center">
              <div class="col">
                <base-input
                  v-model="form.textButton"
                  name="name"
                  placeholder="Texto del botón"
                ></base-input>
              </div>
              <div class="col">
                <base-input
                  v-model="form.urlButton"
                  name="name"
                  placeholder="Url del botón"
                ></base-input>
              </div>
              <div class="col" style="padding-top: 15px">
                <base-button
                  class="btn add-button"
                  style="color: white !important; height: 50px !important"
                  @click="closeAddButton()"
                  >REMOVER BOTÓN
                </base-button>
              </div>
            </div>
          </div>
        </div>

        <md-dialog-actions
          class="modal-footer"
          style="flex-flow: inherit !important"
        >
          <md-button
class="md-primary w-auto"
@click="closeModal"
            >CANCELAR
          </md-button>
          <base-button
            :loading="isLoading"
            class="md-primary w-auto"
            @click="submit"
            >{{ form && form.id ? 'ACTUALIZAR' : 'CREAR' }}
          </base-button>
        </md-dialog-actions>
      </div>
    </div>
  </md-dialog>
</template>

<script>
import BaseButton from '../../../../../components/BaseButton';
import { quillEditor } from 'vue-quill-editor';
import Multiselect from 'vue-multiselect';
import { CREATE_ADVERTISEMENT } from '../../../../../store/actions/advertisement';
import { UPDATE_ADVERTISEMENT } from '../../../../../store/actions/advertisement';
import validator from 'validator';

export default {
  name: 'AdvertisementDialog',
  components: {
    BaseButton,
    quillEditor,
    Multiselect,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedAdvertisement: {
      type: Object,
      default: () => undefined,
    },
  },
  emits: ['close-modal', 'success'],
  data() {
    return {
      isLoading: false,
      statuses: [
        {
          id: 'DRAFT',
          label: 'Editando',
        },
        {
          id: 'PUBLISH',
          label: 'Publicado',
        },
      ],
      isAddButton: false,
      form: {
        title: '',
        content: '',
        poster: {
          url: '',
        },
        section: '',
        textButton: '',
        urlButton: '',
        status: '',
      },
      poster: undefined,
      editorOption: {
        placeholder: 'Descripción',
      },
    };
  },
  computed: {
    open: {
      // getter
      get: function () {
        return this.isOpen;
      },
      // setter
      set: function () {
        this.closeModal();
      },
    },
  },
  watch: {
    selectedAdvertisement: function (advertisement) {
      if (advertisement && advertisement.id) {
        this.form = {
          id: advertisement.id,
          title: advertisement.title,
          content: advertisement.content,
          poster: {
            url: advertisement.poster.url,
          },
          textButton: advertisement.textButton,
          urlButton: advertisement.urlButton,
          status: this.statuses.find((_s) => _s.id === advertisement.status),
        };

        if (advertisement.textButton || advertisement.urlButton) {
          this.openAddButton();
        }
      } else {
        this.reset();
      }
    },
  },
  methods: {
    validateAdvertisementForm() {
      const errors = [];

      if (!this.form.title) errors.push('El titulo es requerido');

      if (!this.form.content) errors.push('El contenido es requerido');

      if (!this.form.status) errors.push('El status es requerido');

      if (this.form.textButton && !this.form.urlButton)
        errors.push('La url es requerida');

      if (this.form.urlButton && !validator.default.isURL(this.form.urlButton))
        errors.push('El formato de la url es incorrecto');

      return errors;
    },
    submit() {
      const errors = this.validateAdvertisementForm();
      if (errors.length) return this.notify('danger', errors.join('<br/>'));

      const data = new FormData();
      data.append('title', this.form.title);
      data.append('content', this.form.content);
      data.append('status', this.form.status.id);
      data.append('text_button', this.form.textButton);
      data.append('url_button', this.form.urlButton);

      if (this.poster) {
        data.append('poster', this.poster);
      }
      this.isLoading = true;

      if (this.form && this.form.id) {
        data.append('id', this.form.id);
        this.updateAdvertisement(data);
      } else {
        this.createAdvertisement(data);
      }
    },
    updateAdvertisement(data) {
      this.$store
        .dispatch(UPDATE_ADVERTISEMENT, data)
        .then(() => {
          this.isLoading = false;
          this.$emit('success');
          this.notify('success', 'Se ha actualizado el anuncio correctamente');
          this.closeModal();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para actualizar el anuncio');
          this.isLoading = false;
        });
    },
    createAdvertisement(data) {
      this.$store
        .dispatch(CREATE_ADVERTISEMENT, data)
        .then(() => {
          this.isLoading = false;
          this.$emit('success');
          this.notify('success', 'Se ha creado el anuncio correctamente');
          this.closeModal();
        })
        .catch(() => {
          this.notify('danger', 'Tenemos problemas para crear el anuncio');
          this.isLoading = false;
        });
    },
    closeModal() {
      this.reset();
      this.$emit('close-modal');
    },
    openAddButton() {
      this.isAddButton = true;
    },
    closeAddButton() {
      this.isAddButton = false;
      this.form = {
        ...this.form,
        textButton: '',
        urlButton: '',
      };
    },
    getFile(event) {
      const files = event.target.files;
      this.form.poster.url = URL.createObjectURL(files[0]);
      this.poster = files[0];
    },
    notify(type, message) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    openInputFile() {
      document.getElementById('file-input').click();
    },
    reset() {
      this.isAddButton = false;
      this.form = {
        id: '',
        title: '',
        content: '',
        poster: {
          url: '',
        },
        section: '',
        textButton: '',
        urlButton: '',
      };
      this.poster = undefined;
    },
  },
};
</script>

<style>
.add-button {
  background: #b7227e;
  color: #fff;
  font-weight: 500;
  padding: 11px 14px;
  font-size: 13px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  border: none;
}

.ql-editor b,
strong {
  font-weight: 600;
}
</style>
