<template>
  <div class="overlay-logo">Logo</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  PAYPAL_AUTHORIZE_LINK,
  PAYPAL_CHECKOUT_SUCCESS,
} from '@/store/actions/paypal';

export default {
  name: 'PaypalCheckoutAccount',
  data() {
    return {};
  },
  created() {
    this.$store
      .dispatch(PAYPAL_CHECKOUT_SUCCESS, {
        token: this.$route.query.token,
        user_id: this.$route.query.userid,
      })
      .then((resp) => {
        window.location.replace(
          this.$route.query.return_url + '?paypalstatus=success',
        );
      })
      .catch((err) => {
        window.location.replace(
          this.$route.query.return_url + '?paypalstatus=cancel',
        );
      });
  },
};
</script>

<style lang="scss">
.overlay-logo {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 100;
}
</style>
