<template>
  <div class="highlight-body">
    <!-- Page content -->
    <div class="container mt-2">
      <!-- Table -->
      <div class="row justify-content-center auth-super-container">
        <div class="auth-container padding-password">
          <div class="auth-container__logo">
            <router-link :to="'/'">
              <img
                :src="require(`@/assets/images/logo/logo-maquillate-white.png`)"
                alt="Maquillate logo"
                class="auth-logo-white"
              />
              <img
                :src="require(`@/assets/images/logo/logo.png`)"
                alt="Maquillate logo"
                class="auth-logo-black"
              />
            </router-link>
          </div>
          <div class="auth-container__form mt-4">
            <div>
              <div class="bg-white rounded mb-0">
                <div class="card-body">
                  <div class="text-center text-muted mb-4">
                    <h2 class="text-default">RECUPERAR CONTRASEÑA</h2>
                    <h3 class="text-default">
                      Ingrese su correo electrónico para iniciar el proceso
                    </h3>
                  </div>
                  <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                  >
                    <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                      <md-field class="mb-0">
                        <label class="auth-container__label">Email</label>
                        <md-input
                          alternative
                          class="mb-0"
                          name="Email"
                          :rules="{ required: true, email: true }"
                          v-model="model.email"
                        ></md-input>
                      </md-field>

                      <div class="text-center">
                        <div
                          v-if="recoveryStatus == 'loading'"
                          class="sp sp-circle"
                        ></div>

                        <base-button
                          v-else
                          block
                          type="dark"
                          native-type="submit"
                          class="mt-4"
                        >Continuar
                        </base-button
                        >
                      </div>

                      <base-alert
                        class="mt-3"
                        v-if="recoveryStatus == 'error'"
                        type="danger"
                      >
                        <strong>Error</strong> Parece que el correo no esta
                        registrado. intentalo de nuevo.
                      </base-alert>
                    </form>
                  </validation-observer>
                </div>
              </div>
              <div>
                <div class="col-12 text-center mt-3">
                  <router-link to="/login" class="auth-link">
                    <small class="text-white"
                    >¿Ya tienes una cuenta? Inicia sesión
                    </small>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { RECOVERY_REQUEST } from '@/store/actions/recovery';
import { mapGetters } from 'vuex';

export default {
  name: 'recovery-password',
  data () {
    return {
      model: {
        email: '',
      },
    };
  },
  methods: {
    onSubmit () {
      let data = {
        email: this.model.email,
      };
      this.$store
        .dispatch(RECOVERY_REQUEST, data)
        .then((response) => {
          this.$router.push('/recuperar-correo');
        })
        .catch((error) => {
          this.model.errorMessage = error.response.data.message;
        });
    },
  },
  computed: {
    ...mapGetters(['recoveryStatus']),
  },
};
</script>

<style></style>
