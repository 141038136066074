<template>
  <div class="container-fluid">
    <h3>Partner - {{ getPartnerCourses.name }}</h3>
    <div
      class="col-12 row justify-content-between container-instructors__search pr-0 pl-0 ml-0 mb-3"
    >
      <div class="col-7 pl-0">
        <form class="d-flex">
          <i class="fas fa-search fa-flip-horizontal"></i>
          <input
            class="form-control me-2"
            type="text"
            placeholder="Buscar por nombre"
            aria-label="Search"
          />
        </form>
      </div>
    </div>
    <div class="container-instructors container-instructors__instructor">
      <div class="container-fluid container-category px-0">
        <md-table>
          <md-table-row class="container-instructors__head">
            <md-table-head>Nombre del curso</md-table-head>
            <md-table-head>Precio</md-table-head>
            <md-table-head>Categoria</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
          <md-table-row
            v-for="course in getPartnerCourses.courses"
            :key="course.id"
            class="container-instructors__cell"
          >
            <md-table-cell>
              {{ course.name }}
            </md-table-cell>
            <md-table-cell>
              {{ course.free !== 0 ? 'Gratis' : course.price }}
            </md-table-cell>
            <md-table-cell>
              <ul
                v-for="category in course.categories"
                :key="category.id"
                class="list-group"
              >
                <li class="list-group-item categories text-capitalize">
                  {{ category.title }}
                </li>
              </ul>
            </md-table-cell>
            <md-table-cell class="container-instructors__options">
              <span class="float-right">
                <md-menu md-size="auto" :md-offset-x="-147" :md-offset-y="-10">
                  <md-button md-menu-trigger>•••</md-button>

                  <md-menu-content>
                    <md-menu-item>
                      <router-link
                        class="dropdown-item-edit p-0"
                        :to="'/admin/cursos/' + course.id"
                      >
                        Editar
                      </router-link>
                    </md-menu-item>
                    <md-menu-item>
                      <a
                        class="dropdown-item p-0"
                        href
                        @click="confirmDeleteCourse(course.id)"
                      >
                        Eliminar
                      </a>
                    </md-menu-item>
                  </md-menu-content>
                </md-menu>
              </span>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
    <ModalDelete
      :confirm-delete="confirmDelete"
      @closeModal="closeModalDelete"
      @deleted="deleteCourse"
    />
  </div>
</template>

<script>
import { GET_PARTNER_COURSES } from '@/store/actions/partner';
import ModalDelete from '@/components/ModalDelete';
import { mapGetters } from 'vuex';
export default {
  components: {
    ModalDelete,
  },
  data: function () {
    return {
      courses: [],
      name: '',
      confirmDelete: false,
    };
  },
  computed: {
    ...mapGetters(['getPartnerCourses']),
  },
  mounted() {
    let data = {
      id: this.$route.params.id,
    };

    this.$store.dispatch(GET_PARTNER_COURSES, { id: data.id });
  },
  methods: {
    async deleteCourse() {
      this.closeModalDelete();
      this.idCourse = null;
    },
    confirmDeleteCourse(id) {
      this.idCourse = id;
      this.confirmDelete = true;
    },
    closeModalDelete() {
      this.confirmDelete = false;
    },
  },
};
</script>

<style scoped>
.categories {
  border: none !important;
}
.dropdown-item {
  color: #000;
}
a.dropdown-item-edit {
  font-size: 14px !important;
  color: #000 !important;
  text-decoration: none;
}
</style>
