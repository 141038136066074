<template>
  <!--  <div class="auth-container">-->
  <!--    <div class="inner-container-layout">-->
  <!--      <h2>Se ha cambiado su contraseña exitosamente</h2>-->
  <!--      <router-link to="/login" class="text-light">-->
  <!--        <a>Iniciar sesion</a>-->
  <!--      </router-link>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="auth-layout-container bg-default">
    <div class="auth-container p-0 m-0">
      <div class="auth-super-container auth-super-container__confirmadet">
        <div class="auth-super-container container">
          <div class="row justify-content-center">
            <div class="xs-size">
              <div class="auth-container__logo">
                <img
                  :src="
                    require(`@/assets/images/logo/logo-maquillate-white.png`)
                  "
                  alt="Maquillate logo"
                  class="img__confirmadet"
                />
              </div>
              <div class="auth-container">
                <div class="bg-white mt-4 rounded mb-0 auth-container__form">
                  <div class="card-body">
                    <div class="text-center text-muted mb-0">
                      <div class="auth-container__form__title text-default">
                        <h2 class="text-default">
                          Se ha cambiado su contraseña exitosamente
                        </h2>
                      </div>
                      <div class="auth-container__form__links">
                        <router-link to="/login">
                          <button class="btn btn-maquillate mt-2 py-2">
                            Iniciar sesion
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PasswordResetSuccess',
  data() {
    return {};
  },
};
</script>
