<template>
  <div class="auth-layout-container bg-default">
    <div class="auth-container p-0 m-0">
      <div class="auth-super-container auth-super-container__confirmadet">
        <div class="auth-super-container container">
          <div class="row justify-content-center">
            <div class="xs-size">
              <div class="auth-container__logo">
                <img
                  :src="
                    require(`@/assets/images/logo/logo-maquillate-white.png`)
                  "
                  alt="Maquillate logo"
                  class="img__confirmadet"
                />
              </div>
              <div class="auth-container">
                <div class="bg-white mt-4 rounded mb-0 auth-container__form">
                  <div class="card-body">
                    <div class="text-center text-muted mb-0">
                      <div class="auth-container__form__title text-default">
                        <h3 class="text-default">
                          Hola, {{ this.getProfile.name }}
                        </h3>
                        <h2 class="text-default">
                          TU CUENTA SE ENCUENTRA INACTIVA
                        </h2>
                      </div>

                      <hr
                        class="my-4"
                        style="width: 100%; border-top: 3px solid #f0f0f0"
                      />

                      <div class="auth-container__form__links inactive-logout-links">
                        <a @click="logout">Cerrar sesión</a>

                        <a @click="explore">Explorar nuestros cursos</a>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <notifications></notifications>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/login';

export default {
  name: 'InactiveUser',

  computed: {
    ...mapGetters(['getProfile']),
  },
  methods: {
     logout() {
      this.$store.dispatch(AUTH_LOGOUT, this.getProfile.id).then(() => {
        window.location.href = '/';
      });
    },
    explore() {
      this.$store.dispatch(AUTH_LOGOUT, this.getProfile.id).then(() => {
        window.location.href = '/explorar';
      });
    },
  },
};
</script>

<style>
.inactive-logout-links a{
     cursor:pointer;
}

</style>
