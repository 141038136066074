<template>
  <div>
    <div class="col-12 mt-4 pl-0 text-center buttons-payment-mobile">
      <base-button
        @click="testStripeGroup(getPaymentPending)"
        :disabled="this.stateS"
        class="px-3 btn-maquillate"
        size="lg"
        type="primary"
      >
        Pagar todos Stripe</base-button
      >
      <base-button
        @click="testAllPaypal(getPaymentPending)"
        :disabled="this.stateP"
        class="px-3 btn-maquillate"
        size="lg"
        type="primary"
      >
        Pagar todos Paypal</base-button
      >
    </div>
    <md-table class="px-0">
      <md-table-row class="container-instructors__head table-striped">
        <md-table-head>Creado</md-table-head>
        <md-table-head>Socio</md-table-head>
        <md-table-head>Estado del pago</md-table-head>
        <md-table-head>Dia de pago</md-table-head>
        <md-table-head>Metodo Actual</md-table-head>
        <md-table-head>Monto</md-table-head>
        <md-table-head>Pagar</md-table-head>
      </md-table-row>
      <!--     Data table -->
      <md-table-row
        class="container-instructors__cell"
        v-for="order in getPaymentPending"
        :key="order.id"
      >
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ getDateFormat(order.created_at) }}
            </p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ order.name }}
            </p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <span class="bagde-pending" v-if="order.status == 'pending'">
              Pendiente
            </span>
          </div>
        </md-table-cell>

        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">
              {{ getDateFormat(order.payment_date) }}
            </p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder" v-if="order.payments">
              {{ order.payments.name }}
            </p>
            <p class="p-0 m-0 font-weight-bolder" v-else>No tiene</p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <p class="p-0 m-0 font-weight-bolder">${{ order.amount }}</p>
          </div>
        </md-table-cell>
        <md-table-cell>
          <div>
            <button
              class="btn btn-buy2"
              @click="testStripeUnit(order)"
              v-if="order.payments && order.payments.payment_method_id === 2"
            >
              pagar
            </button>
            <button
              class="btn btn-buy2"
              @click="testPaypalUnit(order)"
              v-if="order.payments && order.payments.payment_method_id === 1"
            >
              pagar
            </button>
          </div>
        </md-table-cell>
      </md-table-row>
      <!--   END  Data table -->
    </md-table>
    <md-dialog
      :md-active.sync="modals.payment"
      @close="handleClear"
      class="modal-close"
    >
      <span class="material-icons close text-right" @click="handleClear"
        >close</span
      >
      <div class="body-modal-course">
        <md-dialog-title>SOCIO: {{ partner.name }}</md-dialog-title>
        <md-dialog-content class="mt-2">
          <span>¿Esta seguro de pagar?</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>PAGO POR STRIPE</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>DEUDA: ${{ partner.amount }}</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>EMAIL: {{ partner.email }}</span></md-dialog-content
        >

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handleClear"
            >CANCELAR</md-button
          >
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handlePayment(partner)"
            >PAGAR</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
    <md-dialog
      :md-active.sync="modals.paymentGroup"
      @close="handleClear"
      class="modal-close"
    >
      <span class="material-icons close text-right" @click="handleClear"
        >close</span
      >
      <div class="body-modal-course" style="width: 460px">
        <md-dialog-title
          >CANTIDAD DE SOCIOS: {{ partnerGroup.length }}</md-dialog-title
        >

        <md-table class="px-0">
          <md-table-row class="container-instructors__head table-striped">
            <md-table-head class="text-center">Socio</md-table-head>
            <md-table-head class="text-center">Metodo Actual</md-table-head>
            <md-table-head class="text-center">Monto</md-table-head>
          </md-table-row>
          <!--     Data table -->
          <md-table-row
            class="container-instructors__cell"
            v-for="order in partnerGroup"
            :key="order.id"
          >
            <md-table-cell>
              <div>
                <p class="p-0 m-0 font-weight-bolder text-center">
                  {{ order.name }}
                </p>
              </div>
            </md-table-cell>

            <md-table-cell>
              <div>
                <p class="p-0 m-0 font-weight-bolder text-center">Stripe</p>
              </div>
            </md-table-cell>

            <md-table-cell>
              <div>
                <p class="p-0 m-0 font-weight-bolder text-center">
                  ${{ order.amount }}
                </p>
              </div>
            </md-table-cell>
          </md-table-row>
          <!--   END  Data table -->
        </md-table>

        <md-dialog-content class="mt-2">
          <span>¿Esta seguro de pagar?</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>PAGO POR STRIPE</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span
            >DEUDA TOTAL: ${{ this.suma.toFixed(2) }}</span
          ></md-dialog-content
        >

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handleClear"
            >CANCELAR</md-button
          >
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handlePaymentGroup(partnerGroup)"
            >PAGAR</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
    <md-dialog
      :md-active.sync="modals.paymentPaypal"
      @close="handleClear"
      class="modal-close"
    >
      <span class="material-icons close text-right" @click="handleClear"
        >close</span
      >
      <div class="body-modal-course">
        <md-dialog-title>SOCIO: {{ partner.name }}</md-dialog-title>
        <md-dialog-content class="mt-2">
          <span>¿Esta seguro de pagar?</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>PAGO POR PAYPAL</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>DEUDA: ${{ partner.amount }}</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>EMAIL: {{ partner.email }}</span></md-dialog-content
        >

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handleClear"
            >CANCELAR</md-button
          >
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handlePaymentPaypalApi()"
            >PAGAR</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
    <md-dialog
      :md-active.sync="modals.paymentAllPaypal"
      @close="handleClear"
      class="modal-close"
    >
      <span class="material-icons close text-right" @click="handleClear"
        >close</span
      >
      <div class="body-modal-course" style="width: 460px">
        <md-dialog-title
          >CANTIDAD DE SOCIOS: {{ this.cantSocios }}</md-dialog-title
        >

        <md-table class="px-0">
          <md-table-row class="container-instructors__head table-striped">
            <md-table-head class="text-center">Socio</md-table-head>
            <md-table-head class="text-center">Metodo Actual</md-table-head>
            <md-table-head class="text-center">Monto</md-table-head>
          </md-table-row>
          <!--     Data table -->
          <md-table-row
            class="container-instructors__cell"
            v-for="order in partnerPaypal"
            :key="order.id"
          >
            <md-table-cell>
              <div>
                <p class="p-0 m-0 font-weight-bolder text-center">
                  {{ order.name }}
                </p>
              </div>
            </md-table-cell>

            <md-table-cell>
              <div>
                <p class="p-0 m-0 font-weight-bolder text-center">
                  {{ order.methodspaymetn }}
                </p>
              </div>
            </md-table-cell>

            <md-table-cell>
              <div>
                <p class="p-0 m-0 font-weight-bolder text-center">
                  ${{ order.amount.value }}
                </p>
              </div>
            </md-table-cell>
          </md-table-row>
          <!--   END  Data table -->
        </md-table>

        <md-dialog-content class="mt-2">
          <span>¿Esta seguro de pagar?</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span>PAGO POR PAYPAL</span></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span
            >DEUDA TOTAL: ${{ this.suma.toFixed(2) }}</span
          ></md-dialog-content
        >

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handleClear"
            >CANCELAR</md-button
          >
          <md-button
            class="md-button md-primary md-theme-default"
            @click="handlePaymentPaypalApi()"
            >PAGAR</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
    <md-dialog
      :md-active.sync="modals.paymentPaypalConfirm"
      @close="handleClear"
      class="modal-close"
    >
      <!--<span class="material-icons close text-right" @click="handleClear">close</span>-->
      <div class="body-modal-course">
        <md-dialog-content class="mt-2 mb-0">
          <span
            >Pago completado, dale a terminar para proseguir</span
          ></md-dialog-content
        >
        <md-dialog-content class="mt-2 mb-0">
          <span v-if="this.partnerPaypalunit == null"
            >SOCIOS: {{ this.cantSocios }}</span
          ></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span v-if="this.partnerPaypalunit"
            >SOCIO {{ partner.name }}</span
          ></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span v-if="this.partnerPaypalunit == null"
            >TOTAL: {{ this.suma.toFixed(2) }} $</span
          ></md-dialog-content
        >
        <md-dialog-content class="mt-0">
          <span v-if="this.partnerPaypalunit"
            >DEUDA TOTAL: {{ partner.amount }} $</span
          ></md-dialog-content
        >

        <md-dialog-actions>
          <md-button
            class="md-button md-primary md-theme-default"
            style="background-color: #b7227e !important"
            @click="handlePaymentConfirmPaypal()"
            >TERMINAR</md-button
          >
        </md-dialog-actions>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  GET_PAYMENTS_PENDING,
  GET_PAYMENTS_COMPLETE,
  GET_ORDERS,
} from '@/store/actions/payment';
import moment from 'moment-timezone';
import axios from 'axios';
import config from '@/config/config';
import BaseButton from '../../BaseButton.vue';

export default {
  components: { BaseButton },
  name: 'TablePayments',

  data() {
    return {
      //Global
      suma: 0,
      cantSocios: 0,
      //getPaymentPending = this.getPaymentPending,
      statesButtonsModals: {},
      modals: {
        payment: false,
        paymentGroup: false,
        paymentPaypal: false,
        paymentAllPaypal: false,
        paymentPaypalConfirm: false,
      },

      //Stripe
      //paymentMethod: false,
      cantS: 0,
      stateS: true,
      partner: {},
      partnerGroup: [],

      //Paypal
      cantP: 0,
      stateP: true,
      partnerPaypalunit: null,
      /*axiosInstance: axios.create({
        headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + 'A21AAL9asB3gALi8DWv6wKTsb0MYvIl98g-Ph4bGZXaCnFVUldJupIz-ox5DDbFg9cSQkHB82CTfF6fmn_QXJNFyGyNx357yQ' //the token is a variable which holds the token
              }
      }),*/
      linkConfirmPaymentPaypal: '',
      partnerPaypal: [],
      tokenUrlPaypal: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      await this.$store.dispatch(GET_PAYMENTS_PENDING);
      this.stateButtons(this.getPaymentPending);
    },
    async loadPaymentComplete() {
      await this.$store.dispatch(GET_PAYMENTS_COMPLETE);
      await this.$store.dispatch(GET_ORDERS);
    },
    async stateButtons(value) {
      value.map((val) => {
        if (val.payments) {
          if (val.payments.payment_method_id === 1) {
            this.cantP = this.cantP + 1;
          } else if (val.payments.payment_method_id === 2) {
            this.cantS = this.cantS + 1;
          }
        }
      });
      this.botonStripe(this.cantS);
      this.botonPaypal(this.cantP);
    },
    async botonStripe(value) {
      if (value > 1) {
        this.stateS = false;
      }
    },
    async botonPaypal(value) {
      if (value > 1) {
        this.stateP = false;
      }
    },
    async handlePayment(data) {
      await axios
        .post(
          config.data.apiUrl + '/admin/payment/process',
          {
            stripe_connect_id: data.payments.address,
            amount: data.amount,
            partner_id: data.id,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          this.$notify({
            type: 'success',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Pago Realizado con éxito',
          });
          this.load();
          this.loadPaymentComplete();
          this.handleClear();
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Error al realizar el pago',
          });
        });
    },
    async handlePaymentGroup(data) {
      await axios
        .post(
          config.data.apiUrl + '/admin/payment/processGroup',
          {
            data: data,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          this.$notify({
            type: 'success',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Pago Realizado con éxito',
          });
          this.load();
          this.loadPaymentComplete();
          this.handleClear();
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Error al realizar el pago',
          });
        });
    },
    async handlePaymentPaypalApi() {
      await axios
        .post(
          config.data.apiUrl + '/admin/payment/paymentPaypal',
          {
            data: this.partnerPaypal,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          this.tokenUrlPaypal = resp.data;
          console.log(this.tokenUrlPaypal);
          console.log('procesando');
          this.$notify({
            type: 'success',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'pago Realizado con éxito',
          });
          this.modals.paymentPaypalConfirm = true;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            type: 'danger',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Error al realizar el pago',
          });
          this.handleClear();
        });
    },
    async handlePaymentConfirmPaypal() {
      await axios
        .post(
          config.data.apiUrl + '/admin/payment/confirmPayoutsPaypal',
          {
            data: this.tokenUrlPaypal,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
            },
          },
        )
        .then((resp) => {
          console.log(resp.data);
          console.log('procesado');
          this.$notify({
            type: 'success',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Proceso terminado con éxito',
          });
          axios
            .post(
              config.data.apiUrl + '/admin/payment/updatePaymentsPaypal',
              {
                data: resp.data,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('user-token'), //the token is a variable which holds the token
                },
              },
            )
            .then((resp) => {
              console.log(resp.data);
              this.load();
              this.loadPaymentComplete();
              this.handleClear();
              this.$notify({
                type: 'success',
                verticalAlign: 'bottom',
                horizontalAlign: 'left',
                message: resp.data,
              });
              location.reload();
              //this.stateButtons(this.getPaymentPending)
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.handleClear();
        });
    },
    getDateFormat(date) {
      // return moment.tz(date, "America/New_York").fromNow();
      return moment(date).format('ll');
    },
    handleClear() {
      this.modals.payment = false;
      this.modals.paymentGroup = false;
      this.modals.paymentPaypal = false;
      this.modals.paymentAllPaypal = false;
      this.modals.paymentPaypalConfirm = false;
      this.suma = 0;
      this.cantSocios = 0;
    },
    testStripeUnit(value) {
      this.modals.payment = true;
      this.partner = value;
    },
    testStripeGroup(value) {
      this.modals.paymentGroup = true;
      this.partnerGroup = value.filter(
        (value) => value.payments && value.payments.payment_method_id != 1,
      );
      this.suma = this.partnerGroup
        .map((item) => item.amount)
        .reduce((prev, curr) => prev + curr, 0);
      console.log(this.partnerGroup);
    },
    testPaypalUnit(value) {
      this.modals.paymentPaypal = true;
      this.partner = value;
      this.partnerPaypalunit = value;
      this.partnerPaypal = [
        {
          recipient_type: 'EMAIL',
          amount: {
            value: value.amount.toFixed(2),
            currency: 'USD',
          },
          note: 'Thanks for your patronage!',
          sender_item_id: value.id, //Math.floor(Math.random() * (9999999 - 1 + 1)) + 1,
          receiver: value.payments.address,
          alternate_notification_method: {
            phone: {
              country_code: '58',
              national_number: '4147407577',
            },
          },
          notification_language: 'en-EN',
        },
      ];
      console.log(this.partnerPaypal);
    },
    testAllPaypal(value) {
      this.modals.paymentAllPaypal = true;
      this.partnerPaypalunit = null;
      this.partnerPaypal = value
        .filter(
          (value) => value.payments && value.payments.payment_method_id != 2,
        )
        .map((order) => {
          if (order.payments && order.payments.payment_method_id === 1) {
            this.suma = this.suma + order.amount;
            this.cantSocios = this.cantSocios + 1;
            return {
              name: order.name,
              methodspaymetn: 'Paypal',
              recipient_type: 'EMAIL',
              amount: {
                value: order.amount.toFixed(2),
                currency: 'USD',
              },
              note: 'Thanks for your patronage!',
              sender_item_id: order.id, //Math.floor(Math.random() * (9999999 - 1 + 1)) + 1,
              receiver: order.payments.address,
              alternate_notification_method: {
                phone: {
                  country_code: '58',
                  national_number: '4147407577',
                },
              },
              notification_language: 'en-EN',
            };
          }
        });
      console.log(this.partnerPaypal);
    },
  },
  computed: {
    ...mapGetters(['getPaymentPending']),
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}
.btn-two {
  background: #b7227e;
  border-color: #b7227e;
  text-transform: uppercase;
}
</style>
