<template>
  <div class="auth-layout-container bg-default">
    <div class="auth-container p-0 m-0">
      <div class="auth-super-container auth-super-container__confirmadet">
        <div class="auth-super-container container">
          <div class="row justify-content-center">
            <div class="xs-size">
              <div class="auth-container__logo">
                <img
                  :src="
                    require(`@/assets/images/logo/logo-maquillate-white.png`)
                  "
                  alt="Maquillate logo"
                  class="img__confirmadet"
                />
              </div>
              <div class="auth-container">
                <div class="bg-white mt-4 rounded mb-0 auth-container__form">
                  <div class="card-body">
                    <div class="text-center text-muted mb-0">
                      <div class="auth-container__form__title text-default">
                        <h2 class="text-default">
                          REVISA TU BANDEJA DE ENTRADA PARA FINALIZAR EL
                          REGISTRO
                        </h2>
                      </div>
                      <div
                        class="auth-container__form__description text-default"
                      >
                        <h3 class="text-default">
                          Le envíamos un mensaje de registro a:
                        </h3>
                      </div>
                      <div class="mt-2 text-center auth-container__form__email">
                        <h2>
                          <strong>{{ getProfile.email }}</strong>
                        </h2>
                      </div>

                      <hr
                        class="my-4"
                        style="width: 100%; border-top: 3px solid #f0f0f0"
                      />

                      <div class="auth-container__form__links">
                        <a @click="resend">Reenviar enlace de registro </a>

                        <router-link to="/confirmar-correo/cambiar-correo">
                          <a href>Cambiar dirección de correo electrónico</a>
                        </router-link>
                        <button
                          class="btn btn-course float-right mt-2"
                          @click="logout"
                        >
                          CERRAR SESIÓN
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RESEND_VERIFICATION } from '@/store/actions/register';
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT } from '@/store/actions/login';

export default {
  name: 'RegisterSuccess',
  data() {
    return {
      model: {
        email: '',
      },
      loader: true,
    };
  },
  computed: {
    ...mapGetters(['getProfile']),
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT, this.getProfile.id).then(() => {
        window.location.href = '/';
      });
    },
    resend() {
      this.$store
        .dispatch(RESEND_VERIFICATION, this.getProfile.email)
        .then((response) => {
          console.log(response.data);
          this.$notify({
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Se ha reenviado el correo de confirmación con éxito',
          });
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            verticalAlign: 'top',
            horizontalAlign: 'right',
            message: 'Debes esperar un poco para reenviar el correo.',
          });
        });
    },
  },
};
</script>
