<template>
  <div class="container-fluid">
    <div class="card">
      <vue-tabs class="p-3 payments-mobile">
        <v-tab title="Pagos Pendientes">
          <TablePayments />
        </v-tab>
        <v-tab title="Pagos Completados">
          <TableCompleteRewards />
        </v-tab>
        <v-tab title="Ordenes">
          <TableOrders />
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
import { VueTabs, VTab } from 'vue-nav-tabs';
import 'vue-nav-tabs/themes/vue-tabs.css';
import {
  TablePayments,
  TableCompleteRewards,
  TableOrders,
} from '@/components/Admin/Partners';
import { GET_PAYMENTS_COMPLETE } from '@/store/actions/payment';

export default {
  components: {
    VueTabs,
    VTab,
    TablePayments,
    TableCompleteRewards,
    TableOrders,
  },
  data() {
    return {
      bool: false,
      paymentMethod: false,
      modals: {
        paymentMethodModal: false,
        updateMethodModal: false,
      },
      data: {},
      type: '',
      orders: [],
      total: 0,
    };
  },
  methods: {
    async load() {
      await this.$store.dispatch(GET_PAYMENTS_COMPLETE);
    },
    handleClear() {
      this.modals.paymentMethodModal = false;
      this.modals.updateMethodModal = false;
    },
  },
  beforeMount() {
    this.load();
  },
};
</script>

<style>
.vue-tabs .nav-tabs > li.active > a,
.vue-tabs .nav-tabs > li.active > a:hover,
.vue-tabs .nav-tabs > li.active > a:focus {
  background-color: #fff;
  border: none;
  cursor: default;
  border-bottom: 4px solid #000;
  color: #000;
}
.vue-tabs .nav-tabs > li {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}
.vue-tabs .nav-tabs > li > a {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 4px solid white;
}
.vue-tabs .nav > li > a:hover,
.vue-tabs .nav > li > a:focus {
  background-color: transparent;
  border-bottom: 4px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
}
.vue-tabs .nav-tabs {
  border-bottom: none;
}
</style>
