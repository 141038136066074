<template>
  <div class="highlight-body">
    <!-- Page content -->
    <div class="container">
      <!-- Table -->
      <div class="row justify-content-center">
        <div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="xs-size">
                <div class="auth-container">
                  <div class="auth-container__logo pt-5">
                    <router-link :to="'/'">
                      <img
                        class=""
                        :src="
                          require(`@/assets/images/logo/logo-maquillate-white.png`)
                        "
                        alt="Maquillate logo"
                      />
                    </router-link>
                  </div>

                  <div class="bg-white mt-4 rounded mb-0 auth-container__form">
                    <div class="card-body">
                      <div class="text-center text-muted mb-0">
                        <div class="auth-container__form__title text-default">
                          <h2 class="text-default">RECUPERAR CONTRASEÑA</h2>
                        </div>
                        <div
                          class="auth-container__form__description text-default"
                        >
                          <h3 class="text-default">
                            Ingrese su nueva contraseña
                          </h3>
                        </div>
                      </div>
                      <validation-observer
                        ref="formValidator"
                        v-slot="{ handleSubmit }"
                      >
                        <form
                          role="form"
                          @submit.prevent="handleSubmit(onSubmit)"
                        >
                          <md-field class="mb-0">
                            <label class="auth-container__label"
                              >Nueva contraseña</label
                            >
                            <md-input
                              id="password"
                              v-model="model.password"
                              :strength="model.validPassword.strong"
                              type="password"
                              @input="passwordValidation"
                            ></md-input>
                          </md-field>
                          <VuePassword
                            id="repeat-password"
                            v-model="model.repeatPassword"
                            :strength="model.validPassword.strong"
                            class="control has-icons-left md-input"
                            placeholder="repetir Contraseña"
                            type="password"
                          />
                          <md-field class="mt-0">
                            <label class="auth-container__label"
                              >Repetir contraseña</label
                            >
                            <md-input
                              id="model.password"
                              v-model="model.repeatPassword"
                              :strength="model.repeatPassword.strong"
                              type="password"
                              @input="passwordValidation"
                            ></md-input>
                          </md-field>
                          <div></div>
                          <div class="text-center">
                            <base-button
                              :disabled="validateFields"
                              :loading="isLoading"
                              block
                              type="dark"
                              native-type="submit"
                              class="mt-4"
                            >
                              Restaurar Contraseña
                            </base-button>
                          </div>
                        </form>
                      </validation-observer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RECOVERY_CHANGE_REQUEST } from '@/store/actions/recovery';
import { mapGetters } from 'vuex';
import VuePassword from 'vue-password';
import equals from 'validator/lib/equals';

export default {
  name: 'RecoveryPasswordForm',
  components: {
    VuePassword,
  },
  data() {
    return {
      isLoading: false,
      model: {
        password: '',
        repeatPassword: '',
        errors: [],
        token: '',
        rules: [
          { message: 'One lowercase letter required.', regex: /[a-z]+/ },
          { message: 'One uppercase letter required.', regex: /[A-Z]+/ },
          { message: 'One number required.', regex: /[0-9]+/ },
          { message: '8 characters minimum.', regex: /.{8,}/ },
          { message: '11 characters minimum.', regex: /.{11,}/ },
        ],
        validPassword: {
          strong: 0,
          message: '',
          alert: '',
        },
        errorMessage: null,
      },
    };
  },
  computed: {
    ...mapGetters(['recoveryStatus']),

    validateFields: function () {
      return !(
        this.model.validPassword.strong > 0 &&
        this.model.validPassword.strong !== 0 &&
        this.model.password === this.model.repeatPassword &&
        this.model.password.length > 7
      );
    },
  },

  mounted() {
    this.model.email = this.$route.params.email;
    this.model.token = this.$route.params.token;
  },
  methods: {
    ErrorValidation(data) {
      const errors = [];

      if (!data.password) {
        errors.push('No has ingresado una contraseña');
      } else if (!equals(data.password, data.password_confirmation)) {
        errors.push('Las contraseñas deben de ser iguales');
      }

      return errors;
    },
    onSubmit() {
      let data = {
        email: this.model.email,
        token: this.model.token,
        password: this.model.password,
        password_confirmation: this.model.repeatPassword,
      };

      let errors = this.ErrorValidation(data);

      if (errors.length) {
        this.notify(errors.join('</br>'), 'danger');
        return;
      }

      this.isLoading = true;
      this.model.errors = this.passwordValidation();
      this.$store
        .dispatch(RECOVERY_CHANGE_REQUEST, data)
        .then(() => {
          this.isLoading = false;
          this.$router.push('/recuperacion/exitosa');
        })
        .catch((error) => {
          this.isLoading = false;
          this.notify(error.response.data.message, 'danger');
        });
    },
    notify(message, type) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
    passwordValidation() {
      let errors = [];
      let strongPassword =
        /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,}$/;

      for (let condition of this.model.rules) {
        if (!condition.regex.test(this.model.password)) {
          errors.push(condition.message);
        }
      }

      switch (errors.length) {
        case 0: {
          this.model.validPassword.strong = 3;
          this.model.validPassword.message = 'Segura';
          this.model.validPassword.alert = 'text-info';
          break;
        }
        case 1: {
          this.model.validPassword.strong = 3;
          this.model.validPassword.message = 'Segura';
          this.model.validPassword.alert = 'text-info';
          break;
        }
        case 2: {
          this.model.validPassword.strong = 2;
          this.model.validPassword.message = 'Medio';
          this.model.validPassword.alert = 'text-warning';
          break;
        }
        case 3: {
          this.model.validPassword.strong = 1;
          this.model.validPassword.message = 'Debil';
          this.model.validPassword.alert = 'text-warning';
          break;
        }
        default: {
          this.model.validPassword.strong = 0;
          break;
        }
      }

      if (strongPassword.test(this.model.password)) {
        this.model.validPassword.strong = 4;
        this.model.validPassword.message = 'Muy segura';
        this.model.validPassword.alert = 'text-success';
      }
    },
  },
};
</script>

<style scoped>
.auth-layout-container .md-field .md-input,
.auth-layout-container .md-field .md-textarea,
.auth-layout-container .select-country {
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}
</style>
